import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";

Vue.use(Router);

const router = new Router({
  routes: [
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/audit",
        meta: {
            title: "导入日报审核"
        },
        component: () => import("@/views/audit/audit")
    },
    {
        path: "/login",
        component: () => import("@/views/login/index"),
        meta: {
            title: "登陆"
        }
    },
    {
        path: "/register",
        component: () => import("@/views/register/index"),
        meta: {
            title: "注册"
        }
    },
    {
        path: "/expire",
        component: () => import("@/views/expire/index"),
        meta: {
            title: "到期"
        }
    },
    {
        path: "/index",
        component: () => import("@/views/index/index"),
        meta: {
            title: "工时管家",
            keepAlive: true
        }
    },
    {
        path: "/view",
        meta: {
            title: "查看日报"
        },
        component: () => import("@/views/view/index")
    },
    {
        path: "/calendar",
        meta: {
            title: "查看日报"
        },
        component: () => import("@/views/view/calendar")
    },
    {
        path: "/edit",
        meta: {
            title: "填写日报"
        },
        component: () => import("@/views/edit/index")
    },
    {
        path: "/weekedit",
        meta: {
            title: "按周填报"
        },
        component: () => import("@/views/edit/weekEdit")
    },
    {
        path: "/task",
        meta: {
            title: "待办任务"
        },
        component: () => import("@/views/task/index")
    },
    {
        path: "/expense",
        meta: {
            title: "费用报销"
        },
        component: () => import("@/views/expense/index")
    },
    {
        path: "/expenseDetails",
        name: "expenseDetails",
        meta: {
            title: "单据编辑"
        },
        component: () => import("@/views/expense/details")
    },
    {
        path: "/projectInside",
        name: "projectInside",
        meta: {
            title: "项目详情"
        },
        component: () => import("@/views/project/projectInside")
    },
    {
        path: "/editProject",
        meta: {
            title: "编辑项目"
        },
        component: () => import("@/views/project/edit")
    },
    {
        path: "/editask",
        name: "editask",
        meta: {
            title: "编辑任务"
        },
        component: () => import("@/views/task/editask")
    },
    {
        path: "/search", 
        meta: {
            title: "选择项目"
        },
        component: () => import("@/views/edit/search")
    },
    {
        path: "/review",
        meta: {
            title: "审核日报"
        },
        component: () => import("@/views/review/index")
    },
    {
        path: "/profession_review",
        meta: {
            title: "专业审核"
        },
        component: () => import("@/views/review/profession_list")
    },
    {
        path: "/department_review",
        meta: {
            title: "部门审核"
        },
        component: () => import("@/views/review/department_list")
    },
    {
        path: "/msg",
        meta: {
            title: "消息记录"
        },
        component: () => import("@/views/msg/index")
    },
    {
        path: "/project",
        meta: {
            title: "项目管理"
        },
        component: () => import("@/views/project/index")
    },
    {
        path: "/error",
        meta: {
            title: "错误提示"
        },
        component: () => import("@/views/error/index")
    },
    {
        path: "/test",
        meta: {
            title: "测试页面"
        },
        component: () => import("@/views/test/index")
    },
    {
        path: "/tests",
        meta: {
            title: "测试页面2"
        },
        component: () => import("@/views/test/list")
    },
    // {
    //     path: "/timetool",
    //     meta: {
    //         title: "自动计时"
    //     },
    //     component: () => import("@/views/timetool/timetool")
    // },
    {
        path: "/exaLeave",
        meta: {
            title: "请假审批"
        },
        component: () => import("@/views/exaLeave/exaLeave")
    },
    {
        path: "/applyLeave",
        meta: {
            title: "请假申请"
        },
        component: () => import("@/views/exaLeave/applyLeave")
    },
    {
        path: "/awayOffice",
        meta: {
            title: "出差申请"
        },
        component: () => import("@/views/exaLeave/awayOffice")
    },
    {
        path: "/editPerfect",
        meta: {
            title: "完善工号"
        },
        component: () => import("@/views/editPerfect/editPerfect")
    },
    {
        path: "/clearStorage",
        meta: {
            title: "清空存储"
        },
        component: () => import("@/views/test/clearStorage")
    },
    {
        path: "/my",
        component: () => import("@/views/my/index"),
        redirect: "/my/center",
        children: [
            {
                path: "center",
                meta: {
                    title: "个人中心"
                },
                component: () => import("@/views/my/children/center")
            },
            {
                path: "set",
                meta: {
                    title: "修改密码"
                },
                component: () => import("@/views/my/children/set")
            }
        ]
    },
    {
        path: "/count",
        component: () => import("@/views/count/count"),
        meta: {
            title: "填报统计"
        }
    },
    {
        path: "/cost",
        component: () => import("@/views/cost/cost"),
        meta: {
            title: "成本"
        }
    },
    {
        path: "/useRegistration",
        component: () => import("@/views/deviceManagement/useRegistration"),
        meta: {
            title: "设备使用登记"
        }
    },
    {
        path: "/usageHistory",
        component: () => import("@/views/deviceManagement/usageHistory"),
        meta: {
            title: "设备使用记录"
        }
    },
    {
        path: "/pdf",
        meta: {
            title: "PDF"
        },
        component: () => import("@/views/pdf/ppd")
    },
    {
        path: "*",
        component: () => import("@/components/NotFound")
    }
  ]
});

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach((to, from, next) => {
    let { title, needLogin } = to.meta;
    let { isLogin } = store.state;
    document.title = title;

    if (needLogin && !isLogin) {
        next({
            path: "/login"
        });
    } else {
        next();
    }
});

export default router;
