<template>
    <div class="translation">
        <!-- 文本 -->
        <span v-if="corporateWeChat && !noRender.includes(openIdValue)">
            <ww-open-data :type='type' :openid='openIdValue'></ww-open-data>
        </span>
        <span v-else-if="dingdingPlatform && !noRender.includes(openIdValue)">
            <dt-open-data :open-type='dingdingOpenType[type]' :open-id='openIdValue'></dt-open-data>
        </span>
        <span v-else>{{ openIdValue }}</span>
    </div>
</template>

<script>

export default {
    name: '',
    components: {},
    props: {
        type: {
            type: String,
            default: 'userName'
        },
        openid: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            // user: JSON.parse(sessionStorage.getItem("user")),
            user: JSON.parse(localStorage.getItem("userInfo")),
            corporateWeChat: false, // 企业微信转译
            dingdingPlatform: false, // 钉钉转译

            openIdValue: '',
            noRender: ['全部人员', '未分配'],
            dingdingOpenType: {
                userName: 'userName',
                departmentName: 'deptName',
            }
        }
    },
    computed: {},
    watch: {
        openid: {
            handler(newVal, oldVal) {
                this.assignmentValue(newVal)
            },
        }
    },
    created() { },
    mounted() {
        this.dealWith()
    },
    methods: {
        dealWith() {
            console.log(this.user)
            const { userNameNeedTranslate, dingdingUserid } = this.user
            if (userNameNeedTranslate) {
                this.corporateWeChat = true
                if (dingdingUserid) {
                    this.dingdingPlatform = true
                    this.corporateWeChat = false
                } else {
                    this.dingdingPlatform = false
                }
            } else {
                this.corporateWeChat = false
                this.dingdingPlatform = false
            }
            // console.log(this.corporateWeChat, this.dingdingPlatform, this.configurationItems)
            // console.log(this.corporateWeChat, '<=== 企业微信转译')
            // console.log(this.dingdingPlatform, '<=== 钉钉转译')
            this.assignmentValue(this.openid)
        },
        assignmentValue(value) {
            this.openIdValue = value
            if (this.user.dingdingUserid) {
                this.viewConfiguration()
            }
        },
        viewConfiguration() {
            setTimeout(() => {
                window.DTOpenData.update(document.querySelectorAll('dt-open-data'));
            }, 100);
        }
    },
}
</script>
<style scoped>
.translation {
    width: auto;
    display: inline-block;
}
</style>