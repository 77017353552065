<template>
    <div>
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: "app",
    created() { },
    mounted() {
      let width=$("body").width();
      console.log(width)
      if(width>=1200){
        let fontsize=width/1920*40;//fontsize为当前屏幕的基数字体，相对于设计稿计算得到的。
        $("html").css("font-size",`${fontsize}px`)
      }
      if(width<=750){
        let fontsize=width/400*40;
        $("html").css("font-size",`${fontsize}px`)
      }
      //当加载页面的时候设置生效
      window.onresize = () => {
        return (() => {
          let width=$("body").width();
          if(width>=1200){
            let fontsize=width/1920*40;
            $("html").css("font-size",`${fontsize}px`)
          }
          if(width<=750){
            let fontsize=width/400*40;
            $("html").css("font-size",`${fontsize}px`)
          }
        })()
      }
    }
};
</script>

<style>
  .popupDiv {
    background: #F4F4F4;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .popupSearch {
    width: 100%;
  }
  .popupCon {
    width: 100%;
    overflow: auto;
    position: relative;
  }
  .paddingDiv {
    box-sizing: border-box;
    margin: 0 15px;
  }
  .popupTitle {
    color: #333333;
    padding: 0 30px;
    position: relative;
    line-height: 20px;
  }
  .popupTitle::after {
    content: '';
    width: 3px;
    height: 100%;
    border-radius: 3px;
    background: #409eff;
    position: absolute;
    top: 0;
    left: 15px;
  }
  .popupItem {
    background: #FFFFFF;
    padding: 10px 10px;
    border-radius: 6px;
    margin-top: 14px;
    position: relative;
  }
  .marginTop {
    margin-top: 15px;
  }
  .paddingTop {
    padding-top: 10px;
  }
  .popupItem p {
    width: 100%;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    line-height: 22px;
  }
  .popupItemOne {
    font-size: 15px;
    color: #333;
  }
  .popupItemTwo {
    font-size: 13px;
    color: #999;
    margin-top: 10px;
  }
  .popupItemIcon {
    position: absolute;
    right: 7px;
    top: 50%;
    font-size: 20px;
    margin-top: -10px;
    color: #999;
  }

  .popupConBox {
    box-sizing: border-box;
  }
  .borderNone {
    border-radius: 0;
  }
  .marginNone {
    margin: 0 !important;
  }
  .popupBtn {
    padding: 0 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .conBorder {
    width: auto !important;
    margin: 0 15px;
    border-radius: 10px;
    box-sizing: border-box;
  }
  
  .userNameClass_left {
    display: inline-block;
    width: 100px;
  }
  .userNameClass_right {
    display: inline-block;
    width: 150px;
    float: right;
    text-align: right;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }
</style>



