import Vue from "vue";
// import "vant/lib/index.css";
import App from "./App.vue";
import router from "./router/index";
// import "@/assets/style/font/iconfont.css";

import "@/assets/style/index.less";
import $axios from "@/utils/request";
import mixin from "./mixin/index";
import store from "./store/index";

import axios from 'axios'; /* 引入axios进行地址访问*/
Vue.prototype.$http = axios;

import "@/components/Vant";
import './components/Element.js'

import { Form , Toast , Grid, GridItem , DatetimePicker , Popover,
Picker , Dialog , NumberKeyboard , Sticky , Skeleton ,
Panel , Divider , List , pullRefresh , SwipeCell, Checkbox, 
Search, Slider,Stepper,Tag, Calendar, Row, Col, RadioGroup, Radio, 
Loading ,DropdownMenu, DropdownItem, Button, ActionSheet, PullRefresh,Tabbar,
TabbarItem,Uploader,Collapse, CollapseItem,Empty,CheckboxGroup} from 'vant';

Vue.use(Form).use(Toast).use(Grid).use(GridItem).use(DatetimePicker)
.use(Picker).use(Dialog).use(NumberKeyboard).use(Sticky).use(Skeleton)
.use(Panel).use(Divider).use(List).use(pullRefresh).use(SwipeCell)
.use(Checkbox).use(Search).use(Slider).use(Stepper).use(Tag).use(Calendar).use(RadioGroup).use(Radio)
.use(Row).use(Col).use(Loading).use(DropdownMenu).use(DropdownItem).use(Button).use(ActionSheet)
.use(PullRefresh).use(Tabbar).use(TabbarItem).use(Popover).use(Uploader).use(Collapse).use(CollapseItem).use(Empty).use(CheckboxGroup);

// rem
import "amfe-flexible";

import wx from 'weixin-js-sdk'
Vue.prototype.wx = wx
// 把变量挂载到vue的原型上
axios.defaults.baseURL="http://localhost:10010"
Vue.prototype.$axios = $axios;

Vue.mixin(mixin);
// import wx from 'weixin-js-sdk'
// Vue.prototype.$wx = wx

Vue.config.productionTip = false;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import TranslationOpenDataText from '@/components/translationOpenDataText.vue'
Vue.component('TranslationOpenDataText', TranslationOpenDataText)

// 富文本
import 'font-awesome/css/font-awesome.css' 
import VueHtml5Editor from 'vue-html5-editor'
Vue.use(VueHtml5Editor, {
    name: "vue-html5-editor",
    showModuleName: false,
    icons: {
        text: "fa fa-pencil",
        color: "fa fa-paint-brush",
        font: "fa fa-font",
        align: "fa fa-align-justify",
        list: "fa fa-list",
        link: "fa fa-chain",
        unlink: "fa fa-chain-broken",
        tabulation: "fa fa-table",
        image: "fa fa-file-image-o",
        hr: "fa fa-minus",
        eraser: "fa fa-eraser",
        undo: "fa-undo fa",
        "full-screen": "fa fa-arrows-alt",
        info: "fa fa-info",
    },
    image: {
        sizeLimit: 512 * 1024,
        upload: {
            url: null,
            headers: {},
            params: {},
            fieldName: {}
        },
        compress: {
            width: 1600,
            height: 1600,
            quality: 80
        },
        uploadHandler(responseText){
            var json = JSON.parse(responseText)
            if (!json.ok) {
                alert(json.msg)
            } else {
                return json.data
            }
        }
    },
    language: "zh-cn",
    i18n: {
        "zh-cn": {
            "align": "对齐方式",
            "image": "图片",
            "list": "列表",
            "link": "链接",
            "unlink": "去除链接",
            "table": "表格",
            "font": "文字",
            "full screen": "全屏",
            "text": "排版",
            "eraser": "格式清除",
            "info": "关于",
            "color": "颜色",
            "please enter a url": "请输入地址",
            "create link": "创建链接",
            "bold": "加粗",
            "italic": "倾斜",
            "underline": "下划线",
            "strike through": "删除线",
            "subscript": "上标",
            "superscript": "下标",
            "heading": "标题",
            "font name": "字体",
            "font size": "文字大小",
            "left justify": "左对齐",
            "center justify": "居中",
            "right justify": "右对齐",
            "ordered list": "有序列表",
            "unordered list": "无序列表",
            "fore color": "前景色",
            "background color": "背景色",
            "row count": "行数",
            "column count": "列数",
            "save": "确定",
            "upload": "上传",
            "progress": "进度",
            "unknown": "未知",
            "please wait": "请稍等",
            "error": "错误",
            "abort": "中断",
            "reset": "重置"
        }
    },
    hiddenModules: [],
    visibleModules: [
        "text",
        "color",
        "font",
        "align",
        "list",
        "link",
        "unlink",
        "tabulation",
        // "image",
        "hr",
        "eraser",
        "undo",
        "full-screen",
        "info",
    ],
    modules: {}
})

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
